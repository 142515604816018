<template>
  <v-row>
    <v-col cols="12">
      <v-switch :label="$t('active')" v-model="obAudit.active"></v-switch>
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obAudit.name" />
    </v-col>

    <v-col cols="12">
      <v-textarea
        :label="$t('description')"
        outlined
        v-model="obAudit.preview_text"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { Audit } from "@planetadeleste/vue-mc-audits";

@Component
export default class AuditsSettings extends Vue {
  @VModel({ type: Object, default: () => new Audit() })
  obAudit!: Audit;
}
</script>
